@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

@font-face {
   font-family: 'NATS';
   src: url('./../assets//fonts//NATS-Regular.ttf') format('truetype');
   font-weight: normal;
   font-style: normal;
}

:root {
   --white-rgb: 255, 255, 255;
   --charcoal-rgb: 38, 39, 43;
   --blue-rgb: 141, 179, 245;
   --dark-blue-rgb: 111, 147, 209;
   --purple-rgb: 173, 173, 231;
   --green-rgb: 157, 219, 184;
   --red-rgb: 250, 129, 129;
   --turquoise-rgb: 169, 224, 250;
   --pink-rgb: 228, 143, 215;
   --subtle-gray-rgb: rgba(0, 0, 0, 0.1);
   --gray-rgb: rgba(0, 0, 0, 0.2);
   --transition: all 0.3s ease-in-out;
   --box-shadow: 0 4px 8px var(--subtle-gray-rgb);
   --thin-box-shadow: 1px 2px 5px var(--subtle-gray-rgb);
   --thin-box-shadow-hover: 3px 4px 7px var(--subtle-gray-rgb);
   --subtle-border: 1px solid var(--subtle-gray-rgb);
}

/* make nav buttns bolder or some shit (L) */
/* All non paragraph things NATS */

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

html {
   font-size: 62.5%;
   scroll-behavior: smooth;
}

::-webkit-scrollbar {
   width: 5px;
   background: rgba(var(--white-rgb), 1);
}

::-webkit-scrollbar-thumb {
   background: rgba(var(--purple-rgb), 0.5);
   border-radius: 50px;
}

::-webkit-scrollbar-track {
   background: transparent;
}

::-webkit-scrollbar-thumb:hover {
   background: rgba(var(--purple-rgb), 0.7);
}

body {
   font-family: 'Mulish', BlinkMacSystemFont, sans-serif;
   font-weight: 300;
   color: rgba(var(--charcoal-rgb), 1);
   line-height: 1.5;
   letter-spacing: 0.05em;
   background: -webkit-linear-gradient(
      347deg,
      #d0e1ff82,
      #e8dff56e,
      #fde2e454,
      #fff
   );
   background: linear-gradient(103deg, #d0e1ff82, #e8dff56e, #fde2e454, #fff);
   min-height: 100dvh;
   max-width: 130rem;
   padding-bottom: 40px;
   margin: 0 auto;
   overflow-x: hidden;
}

::-moz-selection {
   background: rgba(var(--purple-rgb), 1);
   color: rgba(var(--white-rgb), 1);
}

::selection {
   background: rgba(var(--purple-rgb), 1);
   color: rgba(var(--white-rgb), 1);
}

header {
   display: -ms-grid;
   display: grid;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   margin: 20px 0 50px;
   border-radius: 50px;
}

.logo {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   gap: 10px;
}

.logo object {
   height: 45px;
   width: 45px;
   margin: 0;
}

header nav {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-box-pack: center;
   -ms-flex-pack: center;
   justify-content: center;
}

header nav ul {
   gap: 35px;
   font-weight: 500;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-pack: center;
   -ms-flex-pack: center;
   justify-content: center;
   margin: 0;
   width: 100%;
}

header ul {
   gap: 5px;
   margin-left: auto;
}

.burger-icon {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-pack: end;
   -ms-flex-pack: end;
   justify-content: flex-end;
   cursor: pointer;
}

.exit-icon {
   display: block;
   position: absolute;
   top: 20px;
   right: 20px;
   cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
   font-family: 'NATS', BlinkMacSystemFont, sans-serif;
   font-weight: 500;
}

p,
a,
li,
input,
textarea,
strong,
em,
label,
table,
button {
   font-family: 'Mulish', BlinkMacSystemFont, sans-serif;
   color: rgba(var(--charcoal-rgb), 1);
}

span {
   font-size: inherit;
}

ul {
   list-style: none;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   gap: 30px;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
}

a {
   text-decoration: none;
   color: inherit;
}

img,
object {
   -o-object-fit: cover;
   object-fit: cover;
   height: 100%;
   width: 100%;
   margin: 0 auto;
}

object {
   pointer-events: none;
}

.video-container {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-box-pack: center;
   -ms-flex-pack: center;
   justify-content: center;
}

video {
   -o-object-fit: cover;
   object-fit: cover;
   height: 100%;
   width: 100%;
   border-radius: 20px;
}

input,
textarea {
   padding: 10px 20px;
   border-radius: 50px;
   border: 1px solid rgba(var(--charcoal-rgb), 0.1);
   background: rgba(var(--white-rgb), 1);
   width: 100%;
   -webkit-transition: var(--transition);
   transition: var(--transition);
   color: var(--charcoal-rgb);
}

input:focus,
input:hover,
textarea:focus,
textarea:hover {
   outline: none;
   border: 1px solid rgba(var(--blue-rgb), 0.5);
}

input:focus,
textarea:focus {
   background: rgba(var(--blue-rgb), 0.1);
   border: 1px solid rgba(var(--blue-rgb), 1);
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
   color: rgba(var(--charcoal-rgb), 0.5);
   font-weight: 400;
   -webkit-transition: var(--transition);
   transition: var(--transition);
   font-style: italic;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
   color: rgba(var(--charcoal-rgb), 0.5);
   font-weight: 400;
   -webkit-transition: var(--transition);
   transition: var(--transition);
   font-style: italic;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
   color: rgba(var(--charcoal-rgb), 0.5);
   font-weight: 400;
   -webkit-transition: var(--transition);
   transition: var(--transition);
   font-style: italic;
}

input::placeholder,
textarea::placeholder {
   color: rgba(var(--charcoal-rgb), 0.5);
   font-weight: 400;
   -webkit-transition: var(--transition);
   transition: var(--transition);
   font-style: italic;
}

input:hover::-webkit-input-placeholder,
textarea:hover::-webkit-input-placeholder {
   color: var(--charcoal-rgb);
}

input:hover::-moz-placeholder,
textarea:hover::-moz-placeholder {
   color: var(--charcoal-rgb);
}

input:hover:-ms-input-placeholder,
textarea:hover:-ms-input-placeholder {
   color: var(--charcoal-rgb);
}

input:hover::placeholder,
textarea:hover::placeholder {
   color: var(--charcoal-rgb);
}

textarea {
   border-radius: 10px;
   resize: vertical;
}

.color-swatches {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   gap: 10px;
   -ms-flex-wrap: wrap;
   flex-wrap: wrap;
}

.color-swatches label {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
}

.color-swatches input[type='radio'] {
   display: none;
}

.color-swatches .swatch {
   width: 30px;
   height: 30px;
   border-radius: 50%;
   border: 2px solid transparent;
   cursor: pointer;
   box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
   -webkit-transition: var(--transition);
   transition: var(--transition);
}

.color-swatches input[type='radio']:checked + .swatch {
   -webkit-transform: scale(1.4);
   transform: scale(1.4);
   margin: 0 7px;
}

.input-container {
   position: relative;
   width: 100%;
}

.input-container .btn {
   position: absolute;
   right: 0;
   top: 0;
   border: var(--subtle-border);
   height: 100%;
   width: -webkit-fit-content;
   width: -moz-fit-content;
   width: fit-content;
}

label {
   margin-bottom: 5px;
   display: block;
}

form {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -ms-flex-direction: column;
   flex-direction: column;
   gap: 20px;
}

button,
.btn {
   padding: 10px 15px;
   border-radius: 50px;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-box-pack: center;
   -ms-flex-pack: center;
   justify-content: center;
   cursor: pointer;
   background: transparent;
   border: none;
   gap: 5px;
   width: -webkit-fit-content;
   width: -moz-fit-content;
   width: fit-content;
}

button {
   -webkit-transition: var(--transition);
   transition: var(--transition);
}

.btn.primary {
   background: rgba(var(--dark-blue-rgb), 1);
   color: rgba(var(--white-rgb), 1);
   width: -webkit-fit-content;
   width: -moz-fit-content;
   width: fit-content;
   font-family: 'NATS', BlinkMacSystemFont, sans-serif;
   font-size: 2rem;
   font-weight: 200;
   padding: 0 15px;
}

.btn.secondary {
   border: var(--subtle-border);
   border-radius: 10px;
   background: rgba(var(--white-rgb), 1);
}

.btn.secondary:hover {
   background: rgba(var(--charcoal-rgb), 0.01);
}

button:disabled {
   opacity: 0.5;
   cursor: not-allowed;
}

section {
   background: rgba(var(--white-rgb), 1);
   border-radius: 20px;
   box-shadow: var(--box-shadow);
   -webkit-box-pack: center;
   -ms-flex-pack: center;
   justify-content: center;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   position: relative;
}

section:last-child {
   margin-bottom: 0;
}

section.no-shadow {
   box-shadow: none;
   background: transparent;
   padding: 0;
}

section.text-center {
   text-align: center;
}

.divided-left-right {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   gap: 100px;
   width: 100%;
}

.left-part h5 {
   margin: 15px 0 30px;
   font-weight: 200;
   font-family: 'Mulish', BlinkMacSystemFont, sans-serif;
   font-size: 2rem;
}

.highlight {
   z-index: 1;
   color: rgba(var(--blue-rgb), 1);
}

.container-flex-column {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -ms-flex-direction: column;
   flex-direction: column;
   gap: 10px;
}

.container-flex-row {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: horizontal;
   -webkit-box-direction: normal;
   -ms-flex-direction: row;
   flex-direction: row;
   gap: 20px;
}

.subtext {
   color: rgba(var(--charcoal-rgb), 0.5);
}

.text-smaller-width {
   width: 50%;
   margin: 0 auto;
}

.blue-link {
   color: rgba(var(--blue-rgb), 1);
   font-weight: bold;
   position: relative;
   -webkit-transition: var(--transition);
   transition: var(--transition);
   text-decoration: underline;
   opacity: 0.7;
}

.blue-link:hover {
   opacity: 1;
}

/*  */

ul.message-container {
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -ms-flex-direction: column;
   flex-direction: column;
   padding: 20px;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   position: fixed;
   bottom: 20px;
   right: 20px;
   gap: 0;
   z-index: 9999;
}

ul.message-container li {
   border-radius: 10px;
   box-shadow: var(--thin-box-shadow);
   position: relative;
   width: 300px;
   -webkit-transform: translateY(200px);
   transform: translateY(200px);
   -webkit-transition: var(--transition);
   transition: var(--transition);
   opacity: 0;
   margin-top: 20px;
   padding: 25px 30px;
   position: relative;
   background: rgba(var(--white-rgb), 0.5);
   -webkit-backdrop-filter: blur(5px);
   backdrop-filter: blur(5px);
}

ul.message-container li.active {
   -webkit-transform: translateY(0);
   transform: translateY(0);
   opacity: 1;
}

ul.message-container li.delete {
   opacity: 0;
}

ul.message-container li.positive {
   border: 3px solid rgba(var(--green-rgb), 1);
}

ul.message-container li.negative {
   border: 3px solid rgba(var(--red-rgb), 1);
}

ul.message-container li span {
   position: absolute;
   top: 0px;
   -webkit-transform: translate(-50%, -50%);
   transform: translate(-50%, -50%);
   left: 10%;
}

ul.message-container li p {
   font-size: 1.4rem;
}

ul.message-container li svg {
   position: absolute;
   top: 10px;
   right: 10px;
   cursor: pointer;
   height: 17px;
   width: 17px;
}

/*  */

input.error-input,
textarea.error-input {
   border: 2px solid rgba(var(--red-rgb), 1);
   z-index: 4;
}

.error {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   gap: 10px;
   width: 100%;
   padding: 5px 20px;
   border-radius: 50px;
   -webkit-transition: var(--transition);
   transition: var(--transition);
   opacity: 1;
   position: relative;
   padding-left: 30px;
}

.error p {
   color: rgba(var(--red-rgb), 1);
   font-weight: bold;
}

.error::before {
   content: '⛔️';
   position: absolute;
   left: 7px;
   top: 50%;
   -webkit-transform: translateY(-50%);
   transform: translateY(-50%);
   font-size: 1.3rem;
}

.error.hiddenErr {
   padding: 0 20px;
   padding-left: 30px;
   opacity: 0;
   margin: 0;
}

.spinner,
.notification.in-progress::before {
   width: 15px;
   height: 15px;
   border-radius: 50%;
   -webkit-animation: 1s cubic-bezier(0.63, 0.03, 0.41, 0.93) infinite spin;
   animation: 1s cubic-bezier(0.63, 0.03, 0.41, 0.93) infinite spin;
}

.spinner.white {
   border: 2px solid rgba(var(--white-rgb), 1);
   border-top-color: transparent;
}

.spinner.blue,
.notification.in-progress::before {
   border: 2px solid rgba(var(--blue-rgb), 1);
   border-top-color: transparent;
}

@-webkit-keyframes spin {
   0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
   }
   100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
   }
}

@keyframes spin {
   0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
   }
   100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
   }
}

.rainbow-button {
   background: -webkit-linear-gradient(
      left,
      rgba(var(--blue-rgb), 0.5) 5%,
      rgba(var(--blue-rgb), 1) 25%,
      rgba(var(--purple-rgb), 1) 75%,
      rgba(var(--purple-rgb), 0.5) 95%
   );
   background: linear-gradient(
      90deg,
      rgba(var(--blue-rgb), 0.5) 5%,
      rgba(var(--blue-rgb), 1) 25%,
      rgba(var(--purple-rgb), 1) 75%,
      rgba(var(--purple-rgb), 0.5) 95%
   );
   cursor: pointer;
   border: none;
   margin: 0 auto;
   color: rgba(var(--white-rgb), 1);
   box-shadow: var(--thin-box-shadow);
   -webkit-animation: rotate-colors 10s linear infinite;
   animation: rotate-colors 10s linear infinite;
   background-size: 200%;
   width: 100%;
   font-size: 2.4rem;
   padding: 5px 20px;
   font-family: 'NATS', BlinkMacSystemFont, sans-serif;
   line-height: 35px;
}

@-webkit-keyframes rotate-colors {
   0% {
      background-position: 0% 50%;
   }
   50% {
      background-position: 50% 50%;
   }
   100% {
      background-position: 0% 50%;
   }
}

@keyframes rotate-colors {
   0% {
      background-position: 0% 50%;
   }
   50% {
      background-position: 50% 50%;
   }
   100% {
      background-position: 0% 50%;
   }
}

button.popup-button {
   border: var(--subtle-border);
   border-radius: 50%;
   background: rgba(var(--white-rgb), 1);
   cursor: pointer;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   gap: 2px;
   position: relative;
}

button.popup-button p {
   font-size: 0.6rem;
}

button.danger {
   color: rgba(var(--white-rgb), 1);
   background: rgba(var(--red-rgb), 1) !important;
}

.popup {
   position: fixed;
   left: 50%;
   top: 50%;
   -webkit-transform: translate(-50%, -50%) scale(0);
   transform: translate(-50%, -50%) scale(0);
   background: rgba(var(--white-rgb));
   border-radius: 20px;
   opacity: 0;
   z-index: 999;
   -webkit-transition: var(--transition);
   transition: var(--transition);
}
.popup h3 {
   margin-bottom: 10px;
   padding-bottom: 10px;
   border-bottom: 1px solid rgba(var(--charcoal-rgb), 0.1);

   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
   overflow: hidden;
}

.popup .close {
   cursor: pointer;
   position: absolute;
   top: 15;
   right: 15;
   font-size: 1.2rem;
}

.popup.active {
   opacity: 1;
   z-index: 999;
   -webkit-transform: translate(-50%, -50%) scale(1);
   transform: translate(-50%, -50%) scale(1);
}

.popup .buttons-form {
   -webkit-box-pack: start;
   -ms-flex-pack: start;
   justify-content: flex-start;
   gap: 10px;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: horizontal;
   -webkit-box-direction: reverse;
   -ms-flex-direction: row-reverse;
   flex-direction: row-reverse;
}

.popup .buttons-form button {
   border-radius: 50px;
}

.blurry-overlay {
   position: fixed;
   top: 0;
   left: 0;
   width: 100vw;
   height: 100dvh;
   opacity: 0;
   -webkit-transition: var(--transition);
   transition: var(--transition);
   background: rgba(0, 0, 0, 0.3);
   -webkit-backdrop-filter: blur(4px);
   backdrop-filter: blur(4px);
   visibility: hidden;
}

.blurry-overlay.active {
   visibility: visible;
   opacity: 1;
   z-index: 998;
}

.switch {
   position: relative;
   display: inline-block;
   width: 60px;
   height: 34px;
}

.switch input {
   opacity: 0;
   width: 0;
   height: 0;
}

.slider {
   position: absolute;
   cursor: pointer;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: rgba(var(--charcoal-rgb), 0.2);
   -webkit-transition: var(--transition);
   transition: var(--transition);
   border-radius: 35px;
}

.slider:before {
   position: absolute;
   content: '';
   height: 26px;
   width: 26px;
   left: 4px;
   bottom: 4px;
   background-color: rgba(var(--white-rgb), 1);
   -webkit-transition: var(--transition);
   transition: var(--transition);
   border-radius: 50%;
}

input:checked + .slider {
   background-color: rgba(var(--blue-rgb), 1);
}

input:checked + .slider:before {
   -webkit-transform: translateX(26px);
   transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
   border-radius: 34px;
}

.slider.round:before {
   border-radius: 50%;
}

.has-tooltip {
   position: relative;
}

.has-tooltip {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   gap: 10px;
}

.tooltip {
   position: relative;
   cursor: pointer;
}

.tooltip::after {
   content: attr(data-tooltip);
   position: absolute;
   bottom: 50%;
   -webkit-transform: translate(0, 50%);
   transform: translate(0, 50%);
   left: 280%;
   background-color: rgba(0, 0, 0, 0.8);
   color: rgba(var(--white-rgb), 1);
   padding: 10px;
   border-radius: 5px;
   opacity: 0;
   visibility: hidden;
   -webkit-transition: var(--transition);
   transition: var(--transition);
   font-family: 'Mulish', BlinkMacSystemFont, sans-serif;
   font-weight: 600;
   width: 200px;
   text-align: center;
}

.tooltip:hover::after {
   left: 150%;
   opacity: 1;
   visibility: visible;
}

form.hidden,
.content.hidden {
   display: none;
}

.bold {
   font-weight: bold;
}

.input-with-icon {
   position: relative;
   width: 100%;
}

.input-with-icon input {
   padding-left: 43px;
}

.input-with-icon svg {
   position: absolute;
   -webkit-transform: translate(-50%, -50%);
   transform: translate(-50%, -50%);
   top: 50%;
   left: 25px;
   font-size: 2rem;
   -webkit-filter: brightness(0) saturate(100%) invert(48%) sepia(96%)
      saturate(16%) hue-rotate(76deg) brightness(96%) contrast(98%);
   filter: brightness(0) saturate(100%) invert(48%) sepia(96%) saturate(16%)
      hue-rotate(76deg) brightness(96%) contrast(98%);
   -webkit-transition: var(--transition);
   transition: var(--transition);
   height: 20px;
   width: 50px;
}

.input-with-icon:hover svg {
   -webkit-filter: brightness(0) saturate(100%) invert(0%) sepia(98%)
      saturate(0%) hue-rotate(13deg) brightness(98%) contrast(105%);
   filter: brightness(0) saturate(100%) invert(0%) sepia(98%) saturate(0%)
      hue-rotate(13deg) brightness(98%) contrast(105%);
}

.disabled {
   opacity: 0.35;
   pointer-events: none;
}

span.searched-for {
   position: relative;
}

span.searched-for::before {
   content: '';
   position: absolute;
   height: 3px;
   width: 100%;
   background: rgba(var(--blue-rgb), 1);
   border-radius: 50px;
   bottom: -2px;
}

h5 span.searched-for::before {
   bottom: 10px;
}

.in-line {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
}

.deck-info {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -ms-flex-direction: column;
   flex-direction: column;
   gap: 20px;
   width: 100%;
   height: 100%;
}

.deck-stats {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   gap: 10px;
   -webkit-box-pack: start;
   -ms-flex-pack: start;
   justify-content: flex-start;
}

.deck-stats .widget {
   box-shadow: var(--thin-box-shadow);
   border-radius: 5px;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   gap: 7px;
   padding: 5px;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   width: -webkit-fit-content;
   width: -moz-fit-content;
   width: fit-content;
   -webkit-box-pack: center;
   -ms-flex-pack: center;
   justify-content: center;
}

.popup .deck .description {
   margin-bottom: 10px;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 3;
   overflow: hidden;
}

.popup .deck .deck-stats {
   margin-bottom: 20px;
}

.popup .deck ul.cards,
.popup ul.followers-following-list {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -ms-flex-direction: column;
   flex-direction: column;
   gap: 40px;
   max-height: 300px;
   overflow-y: auto;
   padding: 10px 10px 15px;
   margin: 10px 0;
   position: relative;
   border-bottom: var(--subtle-border);
}

.popup .deck ul.cards li,
.popup ul.followers-following-list li,
section.middle .friends ul.user-list li {
   padding: 20px;
   background: #00000005;
   border-radius: 10px;
   box-shadow: var(--thin-box-shadow);
   position: relative;
   text-align: center;
   width: 100%;
}

.popup .deck ul.cards .front {
   border-bottom: var(--subtle-border);
   margin-bottom: 20px;
   padding-bottom: 20px;
   font-weight: bold;
}

svg {
   -webkit-transition: var(--transition);
   transition: var(--transition);
}

.apple-container {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   gap: 5px;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
}

.apple-emoji {
   font-size: 1.5rem;
}

/*  */
/*  */
/*  */

.image-input-wrapper {
   position: relative;
   display: inline-block;
   cursor: pointer;
   border-radius: 50px;
   position: relative;
   width: 75px;
   height: 75px;
}

.image-input-wrapper .hover {
   position: absolute;
   top: 50%;
   left: 50%;
   -webkit-transform: translate(-50%, -50%);
   transform: translate(-50%, -50%);
   border-radius: 50px;
   -webkit-transition: var(--transition);
   transition: var(--transition);
   height: 100%;
   width: 100%;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-box-pack: center;
   -ms-flex-pack: center;
   justify-content: center;
   opacity: 0;
   background: rgba(0, 0, 0, 0.4);
   -webkit-backdrop-filter: blur(2px);
   backdrop-filter: blur(2px);
}

.image-input-wrapper:hover .hover {
   opacity: 1;
}

.image-input-wrapper svg {
   width: 25px;
   height: 25px;
}

/*  */
/*  */

form.add-card.hidden {
   display: none;
}

form.add-card .btn {
   display: inline-block;
   padding: 10px;
   border: 1px solid #ccc;
   border-radius: 5px;
   text-align: center;
   cursor: pointer;
   -webkit-transition: background-color 0.3s;
   transition: background-color 0.3s;
}

form.add-card .part {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -ms-flex-direction: column;
   flex-direction: column;
   gap: 10px;
}

form.add-card .type-container {
   display: -ms-grid;
   display: grid;
   -webkit-box-pack: center;
   -ms-flex-pack: center;
   justify-content: center;
   -ms-flex-wrap: wrap;
   flex-wrap: wrap;
   gap: 20px;
}

form.add-card .type-container img {
   width: 25px;
   height: 25px;
}

form.add-card .type-container button {
   width: 100%;
}

.hover-above {
   position: absolute;
   top: 7.5px;
   left: 50%;
   width: 100px;
   -webkit-transform: translate(-50%, -100%) scale(1);
   transform: translate(-50%, -100%) scale(1);
   height: auto;
}

.widget p {
   font-family: 'NATS', BlinkMacSystemFont, sans-serif;
   font-size: 1.4rem;
}

/*  */
/*  */

/* styles for extra small devices (phones, >=100px) */
@media only screen and (min-width: 100px) {
   h1 {
      font-size: 4rem;
   }

   h2 {
      font-size: 3rem;
   }

   h3 {
      font-size: 2.5rem;
   }

   h4 {
      font-size: 2rem;
   }

   h5 {
      font-size: 1.7rem;
   }

   p,
   a,
   li,
   input,
   textarea,
   strong,
   em,
   label,
   table,
   button {
      font-size: 1.2rem;
   }

   header {
      padding: 10px 20px;
   }

   video {
      width: 100%;
   }

   section {
      margin: 0 20px 100px;
      padding: 20px;
   }

   .divided-left-right {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      gap: 50px;
   }

   .left-part,
   .right-part {
      width: 100%;
   }

   header {
      -ms-grid-columns: 1.5fr 2.5fr 0.5fr;
      grid-template-columns: 1.5fr 2.5fr 0.5fr;
   }

   header nav {
      position: fixed;
      top: 50%;
      right: 0;
      height: 85vh;
      width: 85vw;
      background: rgba(var(--white-rgb), 1);
      opacity: 1;
      z-index: 2;
      -webkit-transform: translateX(100%) translateY(-50%);
      transform: translateX(100%) translateY(-50%);
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 100px 40px;
      box-shadow: -5px 0px 10px #e1e1e1;
      -webkit-transition: var(--transition);
      transition: var(--transition);
      visibility: hidden;
      z-index: 999;
   }

   header nav.active {
      -webkit-transform: translateX(0) translateY(-50%);
      transform: translateX(0) translateY(-50%);
      visibility: visible;
   }

   header nav ul {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
   }

   header nav a {
      font-family: 'NATS', BlinkMacSystemFont, sans-serif;
      font-size: 1.9rem;
   }

   header ul li.logout-desktop {
      display: none;
   }

   header nav ul.mobile-button {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
   }

   .exit-icon {
      display: block;
   }

   .burger-icon {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
   }

   .rainbow-button {
      /* font-size: 1.8rem; */
   }

   svg {
      height: 18px;
      width: 18px;
   }

   .popup {
      width: 90vw;
      padding: 10px 20px 20px;
   }

   form.add-card .type-container {
      grid-template-columns: repeat(2, 1fr);
   }

   .in-line {
      gap: 20px;
   }

   .popup .buttons-form button {
      width: 50%;
   }
}

/* styles for medium devices (tablets, >=768px) */
@media only screen and (min-width: 768px) {
   h1 {
      font-size: 6rem;
   }

   h2 {
      font-size: 5rem;
   }

   h3 {
      font-size: 4rem;
   }

   h4 {
      font-size: 3.5rem;
   }

   h5 {
      font-size: 2.5rem;
   }

   p,
   a,
   li,
   input,
   textarea,
   strong,
   em,
   label,
   table,
   button {
      font-size: 1.4rem;
   }

   video {
      width: 85%;
   }

   header {
      padding: 10px 80px;
   }

   section {
      margin: 0 50px 150px;
      padding: 40px;
   }

   .divided-left-right {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
   }

   .left-part,
   .right-part {
      width: 50%;
   }

   .rainbow-button {
      font-size: 2.4rem;
   }

   svg {
      height: 23px;
      width: 23px;
   }

   .popup {
      width: 60vw;
      padding: 30px 40px 40px;
   }

   .in-line {
      gap: 50px;
   }

   .popup .buttons-form button {
      width: 45%;
   }
}

/* styles for large devices (desktops, >=992px) */
@media only screen and (min-width: 992px) {
   header {
      grid-template-columns: repeat(3, 1fr);
   }

   header nav {
      position: relative;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      width: 100%;
      background: none;
      opacity: 1;
      z-index: 2;
      -webkit-transform: translateY(0);
      transform: translateY(0);
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      top: 0;
      right: 0;
      padding: 0;
      box-shadow: none;
      visibility: visible;
   }

   header nav ul {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
   }

   header ul li.logout-desktop {
      display: block;
   }

   header nav ul.mobile-button {
      display: none;
   }

   .exit-icon {
      display: none;
   }

   .burger-icon {
      display: none;
   }

   .popup {
      width: 40vw;
   }

   form.add-card .type-container {
      grid-template-columns: repeat(4, 1fr);
   }
}

/* styles for extra large devices (large desktops, >=1200px) */
@media only screen and (min-width: 1200px) {
}

/* styles for extra extra large devices (full-screen monitors, >=1400px) */
@media only screen and (min-width: 1400px) {
}

.skeleton-container {
   position: relative;
   overflow: hidden;
}

.skeleton-container::before {
   content: '';
   position: absolute;
   background: red;
   left: 0;
   top: 50%;
   width: 20px;
   height: 150%;
   filter: blur(20px);
   animation: slide-left 2s linear infinite;
   background: white;
   z-index: 1;
}

.skeleton {
   display: block;
   margin: 7px 0;
   border-radius: 5px;
   background-color: #e5e4e5;
   width: 100%;
   flex-shrink: 0;
   flex-grow: 0;
}

h3 .skeleton {
   height: 40px;
   width: 150px;
}

h4 .skeleton {
   height: 40px;
   width: 50px;
}

h5 .skeleton {
   height: 30px;
   min-width: 50px;
}

p .skeleton {
   height: 10px;
   min-width: 20px;
}

p .skeleton.sub {
   width: 30%;
}

span.skeleton.img {
   height: 35px;
   width: 35px;
   border-radius: 50px;
}

span.skeleton.first {
   min-width: 90px;
}

@keyframes slide-left {
   0% {
      left: -25%;
      transform: rotate(15deg) translateY(-50%);
   }

   100% {
      left: 125%;
      transform: rotate(15deg) translateY(-50%);
   }
}
