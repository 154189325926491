@import "https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap";
@font-face {
  font-family: NATS;
  src: url("NATS-Regular.12b58691.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

:root {
  --white-rgb: 255, 255, 255;
  --charcoal-rgb: 38, 39, 43;
  --blue-rgb: 141, 179, 245;
  --dark-blue-rgb: 111, 147, 209;
  --purple-rgb: 173, 173, 231;
  --green-rgb: 157, 219, 184;
  --red-rgb: 250, 129, 129;
  --turquoise-rgb: 169, 224, 250;
  --pink-rgb: 228, 143, 215;
  --subtle-gray-rgb: #0000001a;
  --gray-rgb: #0003;
  --transition: all .3s ease-in-out;
  --box-shadow: 0 4px 8px var(--subtle-gray-rgb);
  --thin-box-shadow: 1px 2px 5px var(--subtle-gray-rgb);
  --thin-box-shadow-hover: 3px 4px 7px var(--subtle-gray-rgb);
  --subtle-border: 1px solid var(--subtle-gray-rgb);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}

::-webkit-scrollbar {
  background: rgba(var(--white-rgb), 1);
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: rgba(var(--purple-rgb), .5);
  border-radius: 50px;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--purple-rgb), .7);
}

body {
  color: rgba(var(--charcoal-rgb), 1);
  letter-spacing: .05em;
  background: linear-gradient(103deg, #d0e1ff82, #e8dff56e, #fde2e454, #fff);
  max-width: 130rem;
  min-height: 100dvh;
  margin: 0 auto;
  padding-bottom: 40px;
  font-family: Mulish, BlinkMacSystemFont, sans-serif;
  font-weight: 300;
  line-height: 1.5;
  overflow-x: hidden;
}

::selection {
  background: rgba(var(--purple-rgb), 1);
  color: rgba(var(--white-rgb), 1);
}

header {
  display: -ms-grid;
  border-radius: 50px;
  align-items: center;
  margin: 20px 0 50px;
  display: grid;
}

.logo {
  align-items: center;
  gap: 10px;
  display: flex;
}

.logo object {
  width: 45px;
  height: 45px;
  margin: 0;
}

header nav {
  justify-content: center;
  align-items: center;
  display: flex;
}

header nav ul {
  justify-content: center;
  gap: 35px;
  width: 100%;
  margin: 0;
  font-weight: 500;
  display: flex;
}

header ul {
  gap: 5px;
  margin-left: auto;
}

.burger-icon {
  cursor: pointer;
  justify-content: flex-end;
  display: flex;
}

.exit-icon {
  cursor: pointer;
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: NATS, BlinkMacSystemFont, sans-serif;
  font-weight: 500;
}

p, a, li, input, textarea, strong, em, label, table, button {
  color: rgba(var(--charcoal-rgb), 1);
  font-family: Mulish, BlinkMacSystemFont, sans-serif;
}

span {
  font-size: inherit;
}

ul {
  align-items: center;
  gap: 30px;
  list-style: none;
  display: flex;
}

a {
  color: inherit;
  text-decoration: none;
}

img, object {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

object {
  pointer-events: none;
}

.video-container {
  justify-content: center;
  align-items: center;
  display: flex;
}

video {
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 20px;
  width: 100%;
  height: 100%;
}

input, textarea {
  border: 1px solid rgba(var(--charcoal-rgb), .1);
  background: rgba(var(--white-rgb), 1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--charcoal-rgb);
  border-radius: 50px;
  width: 100%;
  padding: 10px 20px;
}

input:focus, input:hover, textarea:focus, textarea:hover {
  border: 1px solid rgba(var(--blue-rgb), .5);
  outline: none;
}

input:focus, textarea:focus {
  background: rgba(var(--blue-rgb), .1);
  border: 1px solid rgba(var(--blue-rgb), 1);
}

input::-webkit-input-placeholder {
  color: rgba(var(--charcoal-rgb), .5);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-style: italic;
  font-weight: 400;
}

textarea::-webkit-input-placeholder {
  color: rgba(var(--charcoal-rgb), .5);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-style: italic;
  font-weight: 400;
}

input::-moz-placeholder {
  color: rgba(var(--charcoal-rgb), .5);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-style: italic;
  font-weight: 400;
}

textarea::-moz-placeholder {
  color: rgba(var(--charcoal-rgb), .5);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-style: italic;
  font-weight: 400;
}

:is(input:-ms-input-placeholder, textarea:-ms-input-placeholder), input::placeholder, textarea::placeholder {
  color: rgba(var(--charcoal-rgb), .5);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-style: italic;
  font-weight: 400;
}

input:hover::-webkit-input-placeholder {
  color: var(--charcoal-rgb);
}

textarea:hover::-webkit-input-placeholder {
  color: var(--charcoal-rgb);
}

input:hover::-moz-placeholder {
  color: var(--charcoal-rgb);
}

textarea:hover::-moz-placeholder {
  color: var(--charcoal-rgb);
}

:is(input:hover:-ms-input-placeholder, textarea:hover:-ms-input-placeholder), input:hover::placeholder, textarea:hover::placeholder {
  color: var(--charcoal-rgb);
}

textarea {
  resize: vertical;
  border-radius: 10px;
}

.color-swatches {
  flex-wrap: wrap;
  gap: 10px;
  display: flex;
}

.color-swatches label {
  align-items: center;
  display: flex;
}

.color-swatches input[type="radio"] {
  display: none;
}

.color-swatches .swatch {
  cursor: pointer;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border: 2px solid #0000;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  box-shadow: 2px 2px 5px #0000004d;
}

.color-swatches input[type="radio"]:checked + .swatch {
  margin: 0 7px;
  transform: scale(1.4);
}

.input-container {
  width: 100%;
  position: relative;
}

.input-container .btn {
  border: var(--subtle-border);
  width: fit-content;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

label {
  margin-bottom: 5px;
  display: block;
}

form {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

button, .btn {
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: fit-content;
  padding: 10px 15px;
  display: flex;
}

button {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.btn.primary {
  background: rgba(var(--dark-blue-rgb), 1);
  color: rgba(var(--white-rgb), 1);
  width: fit-content;
  padding: 0 15px;
  font-family: NATS, BlinkMacSystemFont, sans-serif;
  font-size: 2rem;
  font-weight: 200;
}

.btn.secondary {
  border: var(--subtle-border);
  background: rgba(var(--white-rgb), 1);
  border-radius: 10px;
}

.btn.secondary:hover {
  background: rgba(var(--charcoal-rgb), .01);
}

button:disabled {
  opacity: .5;
  cursor: not-allowed;
}

section {
  background: rgba(var(--white-rgb), 1);
  box-shadow: var(--box-shadow);
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  position: relative;
}

section:last-child {
  margin-bottom: 0;
}

section.no-shadow {
  box-shadow: none;
  background: none;
  padding: 0;
}

section.text-center {
  text-align: center;
}

.divided-left-right {
  align-items: center;
  gap: 100px;
  width: 100%;
  display: flex;
}

.left-part h5 {
  margin: 15px 0 30px;
  font-family: Mulish, BlinkMacSystemFont, sans-serif;
  font-size: 2rem;
  font-weight: 200;
}

.highlight {
  z-index: 1;
  color: rgba(var(--blue-rgb), 1);
}

.container-flex-column {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.container-flex-row {
  flex-direction: row;
  gap: 20px;
  display: flex;
}

.subtext {
  color: rgba(var(--charcoal-rgb), .5);
}

.text-smaller-width {
  width: 50%;
  margin: 0 auto;
}

.blue-link {
  color: rgba(var(--blue-rgb), 1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  opacity: .7;
  font-weight: bold;
  text-decoration: underline;
  position: relative;
}

.blue-link:hover {
  opacity: 1;
}

ul.message-container {
  z-index: 9999;
  flex-direction: column;
  gap: 0;
  padding: 20px;
  display: flex;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

ul.message-container li {
  box-shadow: var(--thin-box-shadow);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  opacity: 0;
  background: rgba(var(--white-rgb), .5);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  width: 300px;
  margin-top: 20px;
  padding: 25px 30px;
  position: relative;
  transform: translateY(200px);
}

ul.message-container li.active {
  opacity: 1;
  transform: translateY(0);
}

ul.message-container li.delete {
  opacity: 0;
}

ul.message-container li.positive {
  border: 3px solid rgba(var(--green-rgb), 1);
}

ul.message-container li.negative {
  border: 3px solid rgba(var(--red-rgb), 1);
}

ul.message-container li span {
  position: absolute;
  top: 0;
  left: 10%;
  transform: translate(-50%, -50%);
}

ul.message-container li p {
  font-size: 1.4rem;
}

ul.message-container li svg {
  cursor: pointer;
  width: 17px;
  height: 17px;
  position: absolute;
  top: 10px;
  right: 10px;
}

input.error-input, textarea.error-input {
  border: 2px solid rgba(var(--red-rgb), 1);
  z-index: 4;
}

.error {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  opacity: 1;
  border-radius: 50px;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 5px 20px 5px 30px;
  display: flex;
  position: relative;
}

.error p {
  color: rgba(var(--red-rgb), 1);
  font-weight: bold;
}

.error:before {
  content: "⛔️";
  font-size: 1.3rem;
  position: absolute;
  top: 50%;
  left: 7px;
  transform: translateY(-50%);
}

.error.hiddenErr {
  opacity: 0;
  margin: 0;
  padding: 0 20px 0 30px;
}

.spinner, .notification.in-progress:before {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: 1s cubic-bezier(.63, .03, .41, .93) infinite spin;
}

.spinner.white {
  border: 2px solid rgba(var(--white-rgb), 1);
  border-top-color: #0000;
}

.spinner.blue, .notification.in-progress:before {
  border: 2px solid rgba(var(--blue-rgb), 1);
  border-top-color: #0000;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.rainbow-button {
  background: -webkit-linear-gradient(left, rgba(var(--blue-rgb), .5) 5%, rgba(var(--blue-rgb), 1) 25%, rgba(var(--purple-rgb), 1) 75%, rgba(var(--purple-rgb), .5) 95%);
  background: linear-gradient(90deg, rgba(var(--blue-rgb), .5) 5%, rgba(var(--blue-rgb), 1) 25%, rgba(var(--purple-rgb), 1) 75%, rgba(var(--purple-rgb), .5) 95%);
  cursor: pointer;
  color: rgba(var(--white-rgb), 1);
  box-shadow: var(--thin-box-shadow);
  background-size: 200%;
  border: none;
  width: 100%;
  margin: 0 auto;
  padding: 5px 20px;
  font-family: NATS, BlinkMacSystemFont, sans-serif;
  font-size: 2.4rem;
  line-height: 35px;
  animation: 10s linear infinite rotate-colors;
}

@keyframes rotate-colors {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 0%;
  }
}

button.popup-button {
  border: var(--subtle-border);
  background: rgba(var(--white-rgb), 1);
  cursor: pointer;
  border-radius: 50%;
  gap: 2px;
  display: flex;
  position: relative;
}

button.popup-button p {
  font-size: .6rem;
}

button.danger {
  color: rgba(var(--white-rgb), 1);
  background: rgba(var(--red-rgb), 1) !important;
}

.popup {
  background: rgba(var(--white-rgb));
  opacity: 0;
  z-index: 999;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(0);
}

.popup h3 {
  border-bottom: 1px solid rgba(var(--charcoal-rgb), .1);
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
  padding-bottom: 10px;
  display: -webkit-box;
  overflow: hidden;
}

.popup .close {
  cursor: pointer;
  font-size: 1.2rem;
  position: absolute;
  top: 15px;
  right: 15px;
}

.popup.active {
  opacity: 1;
  z-index: 999;
  transform: translate(-50%, -50%)scale(1);
}

.popup .buttons-form {
  flex-direction: row-reverse;
  justify-content: flex-start;
  gap: 10px;
  display: flex;
}

.popup .buttons-form button {
  border-radius: 50px;
}

.blurry-overlay {
  opacity: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  backdrop-filter: blur(4px);
  visibility: hidden;
  background: #0000004d;
  width: 100vw;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
}

.blurry-overlay.active {
  visibility: visible;
  opacity: 1;
  z-index: 998;
}

.switch {
  width: 60px;
  height: 34px;
  display: inline-block;
  position: relative;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  cursor: pointer;
  background-color: rgba(var(--charcoal-rgb), .2);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 35px;
  position: absolute;
  inset: 0;
}

.slider:before {
  content: "";
  background-color: rgba(var(--white-rgb), 1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 50%;
  width: 26px;
  height: 26px;
  position: absolute;
  bottom: 4px;
  left: 4px;
}

input:checked + .slider {
  background-color: rgba(var(--blue-rgb), 1);
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.has-tooltip {
  align-items: center;
  gap: 10px;
  display: flex;
  position: relative;
}

.tooltip {
  cursor: pointer;
  position: relative;
}

.tooltip:after {
  content: attr(data-tooltip);
  color: rgba(var(--white-rgb), 1);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-align: center;
  background-color: #000c;
  border-radius: 5px;
  width: 200px;
  padding: 10px;
  font-family: Mulish, BlinkMacSystemFont, sans-serif;
  font-weight: 600;
  position: absolute;
  bottom: 50%;
  left: 280%;
  transform: translate(0, 50%);
}

.tooltip:hover:after {
  opacity: 1;
  visibility: visible;
  left: 150%;
}

form.hidden, .content.hidden {
  display: none;
}

.bold {
  font-weight: bold;
}

.input-with-icon {
  width: 100%;
  position: relative;
}

.input-with-icon input {
  padding-left: 43px;
}

.input-with-icon svg {
  filter: brightness(0) saturate() invert(48%) sepia(96%) saturate(16%) hue-rotate(76deg) brightness(96%) contrast(98%);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 50px;
  height: 20px;
  font-size: 2rem;
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translate(-50%, -50%);
}

.input-with-icon:hover svg {
  filter: brightness(0) saturate() invert(0%) sepia(98%) saturate(0%) hue-rotate(13deg) brightness(98%) contrast(105%);
}

.disabled {
  opacity: .35;
  pointer-events: none;
}

span.searched-for {
  position: relative;
}

span.searched-for:before {
  content: "";
  background: rgba(var(--blue-rgb), 1);
  border-radius: 50px;
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: -2px;
}

h5 span.searched-for:before {
  bottom: 10px;
}

.in-line {
  display: flex;
}

.deck-info {
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
  display: flex;
}

.deck-stats {
  justify-content: flex-start;
  gap: 10px;
  display: flex;
}

.deck-stats .widget {
  box-shadow: var(--thin-box-shadow);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  gap: 7px;
  width: fit-content;
  padding: 5px;
  display: flex;
}

.popup .deck .description {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
  display: -webkit-box;
  overflow: hidden;
}

.popup .deck .deck-stats {
  margin-bottom: 20px;
}

.popup .deck ul.cards, .popup ul.followers-following-list {
  border-bottom: var(--subtle-border);
  flex-direction: column;
  gap: 40px;
  max-height: 300px;
  margin: 10px 0;
  padding: 10px 10px 15px;
  display: flex;
  position: relative;
  overflow-y: auto;
}

.popup .deck ul.cards li, .popup ul.followers-following-list li, section.middle .friends ul.user-list li {
  box-shadow: var(--thin-box-shadow);
  text-align: center;
  background: #00000005;
  border-radius: 10px;
  width: 100%;
  padding: 20px;
  position: relative;
}

.popup .deck ul.cards .front {
  border-bottom: var(--subtle-border);
  margin-bottom: 20px;
  padding-bottom: 20px;
  font-weight: bold;
}

svg {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.apple-container {
  align-items: center;
  gap: 5px;
  display: flex;
}

.apple-emoji {
  font-size: 1.5rem;
}

.image-input-wrapper {
  cursor: pointer;
  border-radius: 50px;
  width: 75px;
  height: 75px;
  display: inline-block;
  position: relative;
}

.image-input-wrapper .hover {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  opacity: 0;
  backdrop-filter: blur(2px);
  background: #0006;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-input-wrapper:hover .hover {
  opacity: 1;
}

.image-input-wrapper svg {
  width: 25px;
  height: 25px;
}

form.add-card.hidden {
  display: none;
}

form.add-card .btn {
  text-align: center;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  transition: background-color .3s;
  display: inline-block;
}

form.add-card .part {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

form.add-card .type-container {
  display: -ms-grid;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  display: grid;
}

form.add-card .type-container img {
  width: 25px;
  height: 25px;
}

form.add-card .type-container button {
  width: 100%;
}

.hover-above {
  width: 100px;
  height: auto;
  position: absolute;
  top: 7.5px;
  left: 50%;
  transform: translate(-50%, -100%)scale(1);
}

.widget p {
  font-family: NATS, BlinkMacSystemFont, sans-serif;
  font-size: 1.4rem;
}

@media only screen and (width >= 100px) {
  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 3rem;
  }

  h3 {
    font-size: 2.5rem;
  }

  h4 {
    font-size: 2rem;
  }

  h5 {
    font-size: 1.7rem;
  }

  p, a, li, input, textarea, strong, em, label, table, button {
    font-size: 1.2rem;
  }

  header {
    padding: 10px 20px;
  }

  video {
    width: 100%;
  }

  section {
    margin: 0 20px 100px;
    padding: 20px;
  }

  .divided-left-right {
    flex-direction: column;
    gap: 50px;
  }

  .left-part, .right-part {
    width: 100%;
  }

  header {
    -ms-grid-columns: 1.5fr 2.5fr .5fr;
    grid-template-columns: 1.5fr 2.5fr .5fr;
  }

  header nav {
    background: rgba(var(--white-rgb), 1);
    opacity: 1;
    z-index: 2;
    -webkit-transition: var(--transition);
    transition: var(--transition);
    visibility: hidden;
    z-index: 999;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    flex-direction: column;
    justify-content: space-between;
    width: 85vw;
    height: 85vh;
    padding: 100px 40px;
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateX(100%)translateY(-50%);
    box-shadow: -5px 0 10px #e1e1e1;
  }

  header nav.active {
    visibility: visible;
    transform: translateX(0)translateY(-50%);
  }

  header nav ul {
    flex-direction: column;
  }

  header nav a {
    font-family: NATS, BlinkMacSystemFont, sans-serif;
    font-size: 1.9rem;
  }

  header ul li.logout-desktop {
    display: none;
  }

  header nav ul.mobile-button {
    display: flex;
  }

  .exit-icon {
    display: block;
  }

  .burger-icon {
    display: flex;
  }

  svg {
    width: 18px;
    height: 18px;
  }

  .popup {
    width: 90vw;
    padding: 10px 20px 20px;
  }

  form.add-card .type-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .in-line {
    gap: 20px;
  }

  .popup .buttons-form button {
    width: 50%;
  }
}

@media only screen and (width >= 768px) {
  h1 {
    font-size: 6rem;
  }

  h2 {
    font-size: 5rem;
  }

  h3 {
    font-size: 4rem;
  }

  h4 {
    font-size: 3.5rem;
  }

  h5 {
    font-size: 2.5rem;
  }

  p, a, li, input, textarea, strong, em, label, table, button {
    font-size: 1.4rem;
  }

  video {
    width: 85%;
  }

  header {
    padding: 10px 80px;
  }

  section {
    margin: 0 50px 150px;
    padding: 40px;
  }

  .divided-left-right {
    flex-direction: row;
  }

  .left-part, .right-part {
    width: 50%;
  }

  .rainbow-button {
    font-size: 2.4rem;
  }

  svg {
    width: 23px;
    height: 23px;
  }

  .popup {
    width: 60vw;
    padding: 30px 40px 40px;
  }

  .in-line {
    gap: 50px;
  }

  .popup .buttons-form button {
    width: 45%;
  }
}

@media only screen and (width >= 992px) {
  header {
    grid-template-columns: repeat(3, 1fr);
  }

  header nav {
    opacity: 1;
    z-index: 2;
    box-shadow: none;
    visibility: visible;
    background: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 100%;
    height: fit-content;
    padding: 0;
    position: relative;
    top: 0;
    right: 0;
    transform: translateY(0);
  }

  header nav ul {
    flex-direction: row;
  }

  header ul li.logout-desktop {
    display: block;
  }

  header nav ul.mobile-button, .exit-icon, .burger-icon {
    display: none;
  }

  .popup {
    width: 40vw;
  }

  form.add-card .type-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

.skeleton-container {
  position: relative;
  overflow: hidden;
}

.skeleton-container:before {
  content: "";
  filter: blur(20px);
  z-index: 1;
  background: #fff;
  width: 20px;
  height: 150%;
  animation: 2s linear infinite slide-left;
  position: absolute;
  top: 50%;
  left: 0;
}

.skeleton {
  background-color: #e5e4e5;
  border-radius: 5px;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  margin: 7px 0;
  display: block;
}

h3 .skeleton {
  width: 150px;
  height: 40px;
}

h4 .skeleton {
  width: 50px;
  height: 40px;
}

h5 .skeleton {
  min-width: 50px;
  height: 30px;
}

p .skeleton {
  min-width: 20px;
  height: 10px;
}

p .skeleton.sub {
  width: 30%;
}

span.skeleton.img {
  border-radius: 50px;
  width: 35px;
  height: 35px;
}

span.skeleton.first {
  min-width: 90px;
}

@keyframes slide-left {
  0% {
    left: -25%;
    transform: rotate(15deg)translateY(-50%);
  }

  100% {
    left: 125%;
    transform: rotate(15deg)translateY(-50%);
  }
}
/*# sourceMappingURL=app.af3c2160.css.map */
